import React from 'react';

const FacebookIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 61 61"
      fill="none"
      className={className}
    >
      <path
        d="M0 30.5C0 47.3447 13.6553 61 30.5 61C47.3447 61 61 47.3447 61 30.5C61 13.6553 47.3447 0 30.5 0C13.6553 0 0 13.6553 0 30.5Z"
        fill="#1877F2"
      />
      <path
        d="M45.75 30.5C45.75 22.1125 38.8875 15.25 30.5 15.25C22.1125 15.25 15.25 22.1125 15.25 30.5C15.25 38.125 20.7781 44.4156 28.0219 45.5594V34.8844H24.2094V30.5H28.0219V27.0687C28.0219 23.2562 30.3094 21.1594 33.7406 21.1594C35.4563 21.1594 37.1719 21.5406 37.1719 21.5406V25.3531H35.2656C33.3594 25.3531 32.7875 26.4969 32.7875 27.6406V30.5H36.9812L36.2188 34.8844H32.5969V45.75C40.2219 44.6063 45.75 38.125 45.75 30.5Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
