import React from 'react';

const LinkedinIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 61 61"
      fill="none"
      className={className}
    >
      <path
        d="M51.4688 0H9.53125C4.26729 0 0 4.26729 0 9.53125V51.4688C0 56.7327 4.26729 61 9.53125 61H51.4688C56.7327 61 61 56.7327 61 51.4688V9.53125C61 4.26729 56.7327 0 51.4688 0Z"
        fill="#2867B2"
      />
      <path
        d="M22.1125 45.75H15.6312V25.3531H22.1125V45.75ZM18.8719 22.4938C16.775 22.4938 15.25 20.9687 15.25 18.8719C15.25 16.775 16.9656 15.25 18.8719 15.25C20.9688 15.25 22.4938 16.775 22.4938 18.8719C22.4938 20.9687 20.9688 22.4938 18.8719 22.4938ZM45.75 45.75H39.2688V34.6938C39.2688 31.4531 37.9344 30.5 36.0281 30.5C34.1219 30.5 32.2156 32.025 32.2156 34.8844V45.75H25.7344V25.3531H31.8344V28.2125C32.4062 26.8781 34.6937 24.7812 37.9344 24.7812C41.5562 24.7812 45.3688 26.8781 45.3688 33.1687V45.75H45.75Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedinIcon;
