const WorkplaceIcon = ({ className = '' }) => {
    return (
      <svg
        className={`h-6 w-6 ${className}`}
        width="32"
        height="45"
        viewBox="0 0 32 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1054 32.7488C14.1459 35.7245 6.99818 31.6479 4.22646 26.7974C1.15152 21.4163 1.36601 15.4332 4.22677 9.93521C6.90873 4.78083 12.1621 1.99943 17.1204 2C22.081 2.00057 25.5041 3.43816 28.0324 5.96758C30.0153 7.95137 31.0081 10.9271 29.0243 13.9027"
          stroke="#3601CD"
          strokeWidth="3"
        />
        <path
          d="M31.0722 12.47L25.9422 29H22.3422L19.0122 16.79L15.6822 29H12.0822L6.92218 12.47H10.4022L13.8522 25.76L17.3622 12.47H20.9322L24.2922 25.7L27.7122 12.47H31.0722Z"
          fill="#3601CD"
        />
      </svg>
    );
  };
  
  export default WorkplaceIcon;
  