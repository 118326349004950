const WeiboIcon = ({ className = '' }) => {
    return (
      <svg
        className={`h-6 w-6 ${className}`}
        width="35"
        height="29"
        viewBox="0 0 35 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.8226 9.95102C28.3421 8.31038 26.9066 6.75177 25.2659 7.1004C23.762 7.42853 23.2972 5.17948 24.7806 4.85819C28.2054 4.11306 31.0902 7.39435 30.0101 10.6551C29.5453 12.1044 27.3577 11.3934 27.8226 9.95102ZM14.6838 28.3468C7.41708 28.3468 0 24.833 0 19.0293C0 16.0009 1.91409 12.5077 5.21588 9.2059C12.0314 2.39039 19.1067 2.30835 17.0832 8.81624C16.8098 9.71176 17.924 9.2059 17.924 9.22641C23.3587 6.9295 27.5287 8.07795 25.7171 12.7401C25.4642 13.3827 25.7923 13.4852 26.2845 13.6356C35.561 16.5273 28.6634 28.3468 14.6838 28.3468ZM24.5071 18.3457C24.138 14.538 19.1409 11.9198 13.3371 12.4872C7.54013 13.0751 3.16508 16.6093 3.53422 20.417C3.90337 24.2246 8.9005 26.8428 14.7043 26.2754C20.5012 25.6875 24.8763 22.1533 24.5071 18.3457ZM23.7825 0.209696C22.012 0.592513 22.6341 3.19704 24.3499 2.82789C29.2923 1.78882 33.5649 6.43731 31.9857 11.3046C31.4799 12.9589 33.975 13.8339 34.5424 12.1249C36.7231 5.30253 30.7758 -1.26688 23.7825 0.209696ZM18.4162 21.4697C17.2473 24.1221 13.8498 25.5713 10.9581 24.6348C8.16904 23.7393 6.99325 20.9844 8.20322 18.5029C9.4132 16.0829 12.5168 14.7157 15.2717 15.4198C18.1428 16.1581 19.5852 18.8515 18.4162 21.4697ZM12.5168 19.4189C11.6349 19.0498 10.4659 19.4394 9.91906 20.3008C9.35168 21.1826 9.62512 22.2148 10.507 22.625C11.4025 23.0352 12.6125 22.6455 13.1798 21.7432C13.7267 20.8476 13.4328 19.8086 12.5168 19.4189ZM14.7453 18.5029C14.3967 18.3867 13.966 18.5439 13.7677 18.872C13.5695 19.2207 13.672 19.5966 14.0207 19.7539C14.3693 19.8906 14.8205 19.7334 15.0187 19.3847C15.2101 19.0293 15.0939 18.6396 14.7453 18.5029Z"
          fill="#A90303"
        />
      </svg>
    );
  };
  
  export default WeiboIcon;
  