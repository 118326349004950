import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "./Header";

const MainContainer = styled.div`
  ${tw`relative p-0 m-0 w-full flex flex-col justify-center items-center overflow-hidden`}
  height: 100vh;
  background-size: cover;
  background-position: center;
  transition: background-image 2s ease-in-out; /* Smooth transition for background image */
`;

const ContentContainer = tw.div`relative py-20 flex flex-col lg:flex-row items-center justify-center h-full w-full`;
const LeftColumn = tw.div`text-center py-8`;

const Title = styled.h1`
  ${tw`text-[48px] font-bold text-white mb-6`}
`;

const Description = styled.p`
  ${tw`text-lg text-white font-semibold sm:text-[20px]  mb-6`}
`;

const Button = styled.button`
  ${tw`rounded`}
`;

const StyledButton = styled.a`
  ${tw`text-center inline-block w-auto py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  background: #FD661F;
  width: 215px;
  height: 59px;
  padding: 20px 30px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

const DotContainer = tw.div`absolute sm:mb-6 mb-2 bottom-0 left-0 w-full flex justify-center`;
const Dot = styled.div`
  ${tw`h-2 w-2 rounded-full mx-1 cursor-pointer`}
  background-color: ${(props) => (props.isActive ? "#D9D9D9" : "#6A6A6A")};
`;

const images = [
  "/nasaimage1.jpg",
  "/nasaimage2.jpg",
  "/nasaimage3.jpg",
  "/nasaimage4.jpg",
];

const titles = [
  "Transforming young dreamers into space explorers!",
  "Join our space voyage!",
  "Fuel your space dreams with us!",
  "Embark on a cosmic journey!",
];

const descriptions = [
  "Our training readies kids for NASA's Space Apps Challenge. Discover, learn, and launch your journey with us.",
  "We offer unique training for young innovators to conquer NASA's Space Apps Challenge. Ignite curiosity, explore, and excel.",
  "Our program equips kids for NASA's Space Apps Challenge. Join, learn, innovate, and reach for the stars!",
  "Our training prepares young minds for NASA's Space Apps Challenge. Inspire, educate and innovate with us.",
];

const YourComponent = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000); // 6s interval between images for smoother transitions

    return () => clearInterval(timer);
  }, []);

  return (
    <MainContainer style={{ backgroundImage: `url(${images[currentImage]})` }}>
      <div className="w-full" style={{ width: "100%" }}>
        <Header />
      </div>
      <ContentContainer>
        <LeftColumn style={{ width: "65%" }}>
          <Title>{titles[currentImage]}</Title>
          <Description>{descriptions[currentImage]}</Description>
          <Button>
            <StyledButton
              onClick={() => {
                window.location.href = "/nasaspaceapps";
              }}
            >
              EXPLORE COURSE
            </StyledButton>
          </Button>
        </LeftColumn>
      </ContentContainer>
      <DotContainer>
        {images.map((image, index) => (
          <Dot key={index} isActive={index === currentImage} />
        ))}
      </DotContainer>
    </MainContainer>
  );
};

export default YourComponent;
