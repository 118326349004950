import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "./light.js";

const ContentContainer = tw.div`flex flex-col lg:flex-row mt-20 `;
const LeftColumn = tw.div`lg:pl-24 `; // Adjust left padding for different screen sizes

const RightColumn = styled.div`
  background-image: url("/aboutus.jpg");
  background-repeat: no-repeat;

  ${tw`bg-contain bg-center h-96 lg:h-[450px] lg:w-[590px] lg:flex-1 md:ml-12 `}
`;

const Content = tw.div`lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;

const Paragraph = styled.p`
  ${tw`max-w-full sm:max-w-[500px] sm:text-lg lg:text-base md:mt-6 md:mb-4`}
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 32px;
  text-align: left;
  color: #696984;
`;

const Icon = styled.img`
  width: 79px;
  height: 79px;
`;

const IconWithText = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.04em;
  text-align: left;
  color: #696984;
  margin-left: 16px;
`;

const AlternateParagraph = styled(Paragraph)`
  font-weight: 500;
  color: #696984;
`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
`;

const WhiteButton = styled.a`
  ${tw`text-center inline-block w-auto py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  background: #F5F5F5;
  width: 104px;
  height: 41px;
  padding: 10px 25px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #252641;
`;

const Heading = styled.h1`
  font-family: Raleway, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: -0.06em;
  text-align: left;
  color: #0b7077;
  margin-top: 12px;
`;

const LinkedInLink = styled.a`
  display: flex;
  font-family: Raleway, sans-serif;
  align-items: center;
  text-decoration: underline;
  color: #2867b2;
  margin-top: 8px;
  margin-left: 8px;
  font-weight: 600;
  font-size: 18px;
`;

const LinkedInIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export default () => {
  return (
    <ContentContainer id="aboutus">
      <LeftColumn>
        <Content>
          <Actions>
            <WhiteButton>About</WhiteButton>
          </Actions>
          <Heading>About Me</Heading>
          <div style={{ display: "flex" }}>
            <Paragraph>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#696984",
                  fontWeight: 700,
                  fontSize: 18,
                }}
              >
                Vamsi Kandikonda,
              </span>{" "}
              Chief AI Evangelist, specializes in teaching Full Stack
              development, Machine Learning, and Python to young learners. My
              mission is to equip students with cutting-edge tech skills, foster
              creativity, and problem-solving abilities, preparing them for tech
              competitions and their future in the rapidly evolving tech
              industry.
            </Paragraph>
          </div>

          <IconWithText>
            <Icon src="/admin.jpg" alt="Your Icon" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Title>Vamsi Kandikonda</Title>
              <LinkedInLink
                href="https://www.linkedin.com/in/kris-vamsi/"
                target="_blank"
              >
                <LinkedInIcon src="/linkedin.jpg" alt="LinkedIn" />
                View My profile
              </LinkedInLink>
            </div>
          </IconWithText>
        </Content>
      </LeftColumn>
      <RightColumn />
    </ContentContainer>
  );
};
