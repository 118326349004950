const WhatsAppIcon = ({ className = '' }) => {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 54 54"
        fill="none"
      >
        <path
          d="M0 27C0 41.9117 12.0883 54 27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27Z"
          fill="#25D366"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.45 17.3813C33.9188 14.85 30.5438 13.5 27 13.5C19.575 13.5 13.5 19.575 13.5 27C13.5 29.3625 14.175 31.725 15.3563 33.75L13.5 40.5L20.5875 38.6437C22.6125 39.6562 24.8062 40.3312 27 40.3312C34.425 40.3312 40.5 34.2563 40.5 26.8312C40.5 23.2875 38.9813 19.9125 36.45 17.3813ZM27 38.1375C24.975 38.1375 22.95 37.6313 21.2625 36.6188L20.925 36.45L16.7062 37.6312L17.8875 33.5812L17.55 33.075C16.3687 31.2188 15.8625 29.1938 15.8625 27.1688C15.8625 21.0938 20.925 16.0312 27 16.0312C30.0375 16.0312 32.7375 17.2125 34.9313 19.2375C37.125 21.4312 38.1375 24.1313 38.1375 27.1688C38.1375 33.075 33.2437 38.1375 27 38.1375ZM33.075 29.7C32.7375 29.5312 31.05 28.6875 30.7125 28.6875C30.375 28.5188 30.2062 28.5188 30.0375 28.8563C29.8687 29.1938 29.1938 29.8687 29.025 30.2062C28.8563 30.375 28.6875 30.375 28.35 30.375C28.0125 30.2062 27 29.8687 25.65 28.6875C24.6375 27.8438 23.9625 26.6625 23.7938 26.325C23.625 25.9875 23.7938 25.8187 23.9625 25.65C24.1313 25.4812 24.3 25.3125 24.4688 25.1437C24.6375 24.975 24.6375 24.8063 24.8063 24.6375C24.975 24.4688 24.8063 24.3 24.8063 24.1313C24.8063 23.9625 24.1313 22.275 23.7938 21.6C23.625 21.0938 23.2875 21.0938 23.1188 21.0938C22.95 21.0938 22.7812 21.0938 22.4437 21.0938C22.275 21.0938 21.9375 21.0937 21.6 21.4312C21.2625 21.7687 20.4188 22.6125 20.4188 24.3C20.4188 25.9875 21.6 27.5063 21.7687 27.8438C21.9375 28.0125 24.1312 31.5562 27.5062 32.9062C30.375 34.0875 30.8813 33.75 31.5563 33.75C32.2313 33.75 33.5812 32.9062 33.75 32.2313C34.0875 31.3875 34.0875 30.7125 33.9188 30.7125C33.75 29.8688 33.4125 29.8688 33.075 29.7Z"
          fill="white"
        />
      </svg>
    );
  };
  
  export default WhatsAppIcon;
  