const LiveJournalIcon = ({ className = '' }) => {
    return (
      <svg
        className={`h-6 w-6 ${className}`}
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="21.6304" cy="21.5" r="17.5" fill="#0FA9DA" />
        <path
          d="M31.1758 32.5289L31.8333 32.6391L31.755 31.9771L30.7028 23.0728L30.5879 22.1008L29.8676 22.7636L21.845 30.1462L21.0929 30.8383L22.1009 31.0073L31.1758 32.5289Z"
          fill="white"
          stroke="#0FA9DA"
        />
        <path
          d="M6.39703 13.3178L14.25 5.97507L30.1944 23.0274L30.7147 29.8265C30.7846 30.7368 29.992 31.4772 29.0886 31.3459L22.3351 30.3645L6.39703 13.3178ZM5.67873 0.98275L1.9915 4.43042C0.84135 5.50585 0.780752 7.31064 1.85618 8.46079L5.09966 11.9297L12.9526 4.58693L9.7091 1.11807C8.63309 -0.0320965 6.82889 -0.0926746 5.67873 0.98275Z"
          fill="white"
        />
      </svg>
    );
  };
  
  export default LiveJournalIcon;
  