import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "./light.js";

const ContentContainer = tw.div`flex flex-col xl:flex-row `;
const LeftColumn = styled.div`
  background-image: url("/nasa.jpg");
  background-repeat: no-repeat;

  ${tw`bg-contain bg-center h-96 lg:h-[525px] lg:w-[554px] md:ml-0 lg:mr-40`}
`;

const RightColumn = styled.div`
  ${tw`flex-1 items-center justify-center ml-8 lg:ml-20 `}
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;

  text-align: left;
  color: #696984;
  margin-top: 16px;
`;

const MainHeading = styled.h1`
  font-family: "Raleway", sans-serif;
  font-size: 48px;
  font-weight: 700;
  width: "100%";

  text-align: left;
  color: #fd661f;
  marginbottom: 4px;
`;

const ResponsiveHeading = styled(Heading)`
  width: 100%;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export default () => {
  return (
    <ContentContainer id="nasa">
      <RightColumn>
        <ResponsiveHeading>
          <MainHeading>NASA Space Apps Challenge</MainHeading>
          <span style={{ color: "#696984", fontWeight: 700 }}>
            Join Young Stars,
          </span>{" "}
          <span>
            our specialized training program designed for kids eager to
            participate in NASA's International Space Apps Challenge. In
            collaboration with global Space Agencies, we inspire young minds to
            solve real-world challenges using open NASA data.
          </span>
        </ResponsiveHeading>
        <ResponsiveHeading>
          <span>
            Our curriculum spans topics from storytelling to astrophysics,
            fostering curiosity and creativity. Here, kids become part of a
            global community, connecting with fellow young innovators. We
            welcome all skill levels and champion an environment where every
            child can thrive. Enroll your child in Young Stars and inspire them
            to reach for the stars and beyond.
          </span>
        </ResponsiveHeading>
        <ResponsiveHeading>
          <span>
            We welcome all skill levels and champion an environment where every
            child can thrive. Enroll your child in Young Stars and inspire them
            to reach for the stars and beyond.
          </span>
        </ResponsiveHeading>
      </RightColumn>
      <LeftColumn />
    </ContentContainer>
  );
};
