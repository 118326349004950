import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/HeroSection.js";
import AboutUs from "components/hero/AboutUs.js";
import PopularCourses from "components/hero/PopularCourses.js";
import Learnnew from "components/hero/Learnnew.js";
import Whyus from "components/hero/Whyus.js";

// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/hero/Faqs.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Footer from "components/hero/footer.js";
import { AnimatePresence } from "framer-motion";
import NASAModal from "components/hero/NASAModal";
import Nasa from "components/hero/Nasa";
import Header from "components/hero/Header";

export default () => {
  // const [openModal, setOpenModal] = useState(false);
  const [isCourseModal, setIsCourseOpen] = useState(false);
  // useEffect(() => {
  //   setOpenModal(true);
  // }, []);
  return (
    <>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {/* {openModal && (
          <NASAModal
            setModal={setOpenModal}
            visible={openModal}
            setIsCourseOpen={setIsCourseOpen}
          />
        )} */}
      </AnimatePresence>
      <AnimationRevealPage>
        <Hero />

        <PopularCourses
          isCourseModal={isCourseModal}
          setIsCourseOpen={setIsCourseOpen}
        />

        <Learnnew />
        <Nasa />
        <AboutUs />
        <Whyus />

        {/* <Blog />
    <Testimonial textOnLeft={true} /> */}
        <FAQ />
        {/* <SubscribeNewsLetterForm /> */}
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
