import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";

const Container = tw.div`relative bg-gray-100 py-16 lg:py-20`;

const Content = tw.div`max-w-screen-xl mx-auto`;

const FAQContent = tw.div`flex flex-wrap -mx-4`;

const FAQColumn = tw.div`w-full sm:w-1/2 px-4`;

const Subheading = tw(SubheadingBase)`text-center lg:text-left`;
const Heading = tw(SectionHeading)`text-center lg:text-left`;
const Description = styled.p`
  ${tw`max-w-xl text-center mx-auto sm:mx-0 lg:max-w-none sm:text-base lg:text-lg mt-4 text-secondary-100`}
  font-family: Raleway;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
`;

const FAQSContainer = tw.div`mt-12 sm:mx-4 grid grid-cols-1 md:grid-cols-2 gap-4`;
const FAQ = styled.div`
  ${tw`cursor-pointer select-none p-4 rounded-lg`}
  background: ${(props) => (props.isOpen ? "#F5F5F5" : "transparent")};
  min-height: 80px;
`;

const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 text-[#3A86FF] p-1 rounded-full group-hover:bg-[#0B7077] group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(
  tw.div`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const CenteredImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 594.19px;
  height: 64.8px;
`;

export default ({
  subheading = "",
  heading = "Questions",
  description = "Onlearing is one powerful online software suite that combines all the tools needed to run a successful school or office.",
  faqs = null,
}) => {
  const defaultFaqs = [
    {
      question: "What is this website all about?",
      answer:
        "This website offers online classes taught by professionals in the field of artificial intelligence and the latest tech topics.",
    },
    {
      question: "Who can use this website?",
      answer:
        "Kids who are interested in learning about artificial intelligence and the latest tech topics can use this website.",
    },
    {
      question: "What kind of classes can I find here?",
      answer:
        "You can find classes about artificial intelligence, machine learning, robotics, cybersecurity, and other latest tech topics.",
    },
    {
      question: "Are the classes free?",
      answer: "Some classes are free, but others may require a fee.",
    },
    {
      question: "Do I need to create an account to access the classes?",
      answer:
        "Yes, you need to create an account to access the classes. You can access the classes by logging in to your account on the website.",
    },
    {
      question: "Can I download the classes?",
      answer:
        "No, you cannot download the classes. They can only be accessed through the website. The classes are designed to be age-appropriate for kids.",
    },
    {
      question: "Can I request a class on a specific topic?",
      answer:
        "Yes, you can make a request for a class on a specific topic. New classes are added regularly, but the frequency may vary.",
    },
    {
      question: "Can I leave feedback or suggestions for new classes?",
      answer: "Yes, you can leave feedback or suggestions for new classes.",
    },
    {
      question:
        "How can I contact the website admin if I have any questions or concerns?",
      answer:
        "You can contact the website admin through the website's contact page or email.",
    },
    {
      question: "Are there any rewards or incentives for completing classes?",
      answer:
        "Yes, there may be rewards or incentives for completing classes, such as certificates or badges.",
    },
    {
      question: "Can I share the classes with my friends?",
      answer:
        "No, you cannot share the classes with your friends as they are only accessible through individual accounts.",
    },
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <CenteredImage src="/faq.jpg" alt="Centered Image" />
        <Description>{description}</Description>
        <FAQSContainer>
          {faqs.map((faq, index) => (
            <FAQ
              key={index}
              onClick={() => {
                toggleQuestion(index);
              }}
              className="group"
              isOpen={activeQuestionIndex === index}
            >
              <Question>
                <QuestionText>{faq.question}</QuestionText>
                <QuestionToggleIcon>
                  {activeQuestionIndex === index ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  ) : (
                    <PlusIcon />
                  )}
                </QuestionToggleIcon>
              </Question>
              <Answer
                variants={{
                  open: { opacity: 1, height: "auto", marginTop: "16px" },
                  collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                }}
                initial="collapsed"
                animate={activeQuestionIndex === index ? "open" : "collapsed"}
                transition={{
                  duration: 0.3,
                  ease: [0.04, 0.62, 0.23, 0.98],
                }}
              >
                {faq.answer}
              </Answer>
            </FAQ>
          ))}
        </FAQSContainer>
      </Content>
    </Container>
  );
};
