const TelegramIcon = ({ className = '' }) => {
    return (
      <svg
        className={`h-6 w-6 ${className}`}
        width="35"
        height="30"
        viewBox="0 0 35 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.9003 2.67216L29.6186 27.5806C29.2201 29.3386 28.1809 29.7761 26.7042 28.9479L18.6566 23.0177L14.7735 26.7524C14.3438 27.1822 13.9843 27.5416 13.1561 27.5416L13.7343 19.3455L28.6497 5.86776C29.2982 5.28958 28.5091 4.96924 27.6418 5.54742L9.20267 17.1578L1.26446 14.6732C-0.462254 14.1341 -0.493507 12.9465 1.62387 12.1183L32.6735 0.156311C34.1111 -0.3828 35.3691 0.476652 34.9003 2.67216Z"
          fill="#039CBD"
        />
      </svg>
    );
  };
  
  export default TelegramIcon;
  