import React from 'react';

const RedditIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3333 3333"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm-511 1795c0-71 57-128 128-128s128 57 128 128-57 128-128 128-128-57-128-128zm766 0c0-71 57-128 128-128s128 57 128 128-57 128-128 128-128-57-128-128zm6 273c33-26 81-20 107 13s20 81-13 107c-92 72-231 119-356 119-124 0-264-47-356-119-33-26-39-74-13-107s74-39 107-13c53 42 157 86 262 86s209-45 262-86zm760-528c0-141-114-256-255-256-96 0-180 53-223 131-131-72-291-118-466-129l152-342 292 84c26 74 97 128 181 128 106 0 192-86 192-192s-86-192-192-192c-73 0-136 41-169 101l-325-94c-36-10-75 7-90 42l-207 465c-170 12-326 58-454 128-44-78-127-131-223-131-141 0-255 114-255 256 0 104 63 194 152 234-16 48-25 98-25 149 0 353 400 639 894 639s894-286 894-639c0-51-9-102-25-149 90-40 152-129 152-234zm-319-647c40 0 72 32 72 72s-32 72-72 72-72-32-72-72 32-72 72-72zM772 1540c0-70 57-128 128-128 51 0 95 30 116 73-67 51-122 109-163 173-47-19-80-65-80-119zm894 870c-410 0-743-218-743-487s332-487 743-487c410 0 743 218 743 487s-332 487-743 487zm814-752c-41-64-96-122-163-173 21-43 65-73 116-73 70 0 128 57 128 128 0 54-33 100-80 119z" />
    </svg>
  );
};

export default RedditIcon;
