import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
const Backdrop = styled(motion.div)((props) => [
  tw`fixed top-0 left-0 bottom-0 right-0 bg-[#00000090] backdrop-blur-sm flex justify-center items-center z-50`,
]);
const ModalMain = styled(motion.div)((props) => [
  tw`w-[90vw] lg:w-[80vw] bg-white rounded-md p-4 flex flex-col lg:flex-row justify-between items-center gap-2 relative`,
]);
const Closebutton = tw.button`w-6 h-6 rounded-full bg-red-400 flex justify-center items-center text-white text-lg absolute top-[8px] right-[8px] active:scale-95 duration-300 transition-all`;
const ImageContainer = tw.img`w-full max-w-[600px] lg:w-1/2 h-full aspect-auto`;
const RightSide = tw.div`w-full lg:w-1/2 h-full flex flex-col justify-center items-center p-4 gap-4 lg:gap-8`;
const CongratulatoryText = styled.div`
  ${tw`w-full text-base md:text-xl lg:text-2xl xl:text-3xl font-semibold`}
  font-family: "Raleway", sans-serif;
  color: #0b7077;
`;
const CallToAction = styled.button`
  ${tw`text-center inline-block w-auto py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300 px-6 py-2 text-base md:text-lg`}
  background: #FD661F;
  border-radius: 10px;
  gap: 10px;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.02em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};
const NASAModal = ({ setModal, visible, setIsCourseOpen }) => {
  return (
    <Backdrop
      onClick={() => {
        setModal(false);
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ModalMain
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <Closebutton
          onClick={() => {
            setModal(false);
          }}
        >
          &#x2717;
        </Closebutton>
        <ImageContainer src="/nasawining.png" />
        <RightSide>
          <CongratulatoryText>
            Kudos to the Hackmasters Team! They clinched the award for the best
            use of Data in the 2023 NASA Space Apps hackathon.
          </CongratulatoryText>
          <CallToAction
            onClick={() => {
              setModal(false);
              setIsCourseOpen(true);
            }}
          >
            Signing up for the 2024 hackathons
          </CallToAction>
        </RightSide>
      </ModalMain>
    </Backdrop>
  );
};

export default NASAModal;
