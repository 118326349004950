import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "./light.js";

const ContentContainer = tw.div`flex flex-col lg:flex-row pt-24`;
const LeftColumn = styled.div`
  background-image: url("/learnnew.jpg");
  background-repeat: no-repeat;

  ${tw`bg-contain bg-center h-96 lg:h-[450px] lg:w-[590px] lg:flex-1 md:ml-12 `}
`;

const RightColumn = styled.div`
  ${tw`flex-1 ml-8 xl:pl-6`}
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = tw.div`lg:mb-24`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 2px;
`;

const CircularDiv = styled.div`
  ${tw`w-10 h-10 rounded-full shadow-lg bg-white flex items-center justify-center mt-6`}
`;

const IconWithText = styled.div`
  ${tw`flex items-center`}
`;

const Title = styled.h2`
  width: 443px;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 500;

  text-align: left;

  color: #696984;
  padding: 16px; /* Add padding to match the height */
`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
`;

const WhiteButton = styled.a`
  ${tw`text-center inline-block w-auto py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  background: #D4E1FF;
  width: 68px;
  height: 41px;
  padding: 10px 25px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0b7077;
`;

const Heading = styled.h1`
  font-family: "Raleway", sans-serif;
  font-size: 48px;
  font-weight: 700;

  text-align: left;
  color: #fd661f;
  margin-top: 16px;
`;

const LinkedInLink = styled.a`
  display: flex;
  font-family: Raleway, sans-serif;
  align-items: center;
  text-decoration: underline;
  color: #2867b2;
  margin-top: 8px;
  margin-left: 8px;
  font-weight: 600;
  font-size: 18px;
`;

const LinkedInIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export default () => {
  return (
    <ContentContainer id="aboutus">
      <LeftColumn />
      <RightColumn>
        <Content>
          <Actions>
            <WhiteButton>AI</WhiteButton>
          </Actions>
          <Heading>Learn New Things AI</Heading>

          <div style={{ display: "flex", marginBottom: "10px" }}>
            <CircularDiv>
              <Icon src="/new1.jpg" alt="Admin Icon" />
            </CircularDiv>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "8px",
              }}
            >
              <Title>
                Expands career opportunities in rapidly growing AI industry.
              </Title>
            </div>
          </div>

          <div style={{ display: "flex", marginBottom: "16px" }}>
            <CircularDiv>
              <Icon src="/new2.jpg" alt="Admin Icon" />
            </CircularDiv>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "8px",
              }}
            >
              <Title>
                Enhances analytical, problem-solving, critical thinking skills.
              </Title>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <CircularDiv>
              <Icon src="/new3.jpg" alt="Admin Icon" />
            </CircularDiv>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "8px",
              }}
            >
              <Title>
                Provides competitive advantage in tech-dominated job market.
              </Title>
            </div>
          </div>
        </Content>
      </RightColumn>
    </ContentContainer>
  );
};
