const MailruIcon = ({ className = '' }) => {
    return (
      <svg
        className={`h-6 w-6 ${className}`}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#0266FD" />
        <path
          d="M20.1094 8C13.417 8 8 13.416 8 20.1094C8 26.8017 13.416 32.2188 20.1094 32.2188C22.4606 32.2188 24.7647 31.5283 26.7211 30.2547C27.3073 29.8731 27.4352 29.0688 26.9922 28.5276L26.4953 27.9205C26.1207 27.4629 25.4611 27.3508 24.9634 27.6701C23.5234 28.5936 21.8337 29.0938 20.1094 29.0938C15.1554 29.0938 11.125 25.0634 11.125 20.1094C11.125 15.1554 15.1554 11.125 20.1094 11.125C24.999 11.125 29.0938 13.9384 29.0938 18.9375C29.0938 20.8313 28.0638 22.8312 26.2534 23.0241C25.4063 23.0019 25.4277 22.3963 25.5954 21.5581L26.7396 15.6445C26.8795 14.9214 26.3256 14.25 25.5891 14.25H23.3928C23.2306 14.25 23.0741 14.3097 22.9531 14.4177C22.8322 14.5257 22.7552 14.6745 22.7369 14.8356L22.7364 14.8401C22.0188 13.966 20.7614 13.7769 19.8082 13.7769C16.1666 13.7769 13.0781 16.8156 13.0781 21.1724C13.0781 24.361 14.8743 26.3418 17.7656 26.3418C19.0832 26.3418 20.5668 25.5783 21.4273 24.4701C21.8922 26.1353 23.4104 26.1353 24.8799 26.1353C30.1977 26.1353 32.2188 22.6386 32.2188 18.9375C32.2188 12.2799 26.8488 8 20.1094 8ZM19.0508 22.8647C17.9644 22.8647 17.2896 22.1019 17.2896 20.874C17.2896 18.6771 18.7924 17.3228 20.1523 17.3228C21.2408 17.3228 21.8907 18.0669 21.8907 19.3135C21.8907 21.5137 20.2366 22.8647 19.0508 22.8647Z"
          fill="#ED9D02"
        />
      </svg>
    );
  };
  
  export default MailruIcon;
  