const FacebookGroupIcon = ({ className = '' }) => {
    return (
      <svg
        className={`h-6 w-6 ${className}`}
        width="30"
        height="30"
        viewBox="0 0 47 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="47" height="45" fill="#4292FE" />
        <path
          d="M13.5 21C15.1547 21 16.5 19.6547 16.5 18C16.5 16.3453 15.1547 15 13.5 15C11.8453 15 10.5 16.3453 10.5 18C10.5 19.6547 11.8453 21 13.5 21ZM34.5 21C36.1547 21 37.5 19.6547 37.5 18C37.5 16.3453 36.1547 15 34.5 15C32.8453 15 31.5 16.3453 31.5 18C31.5 19.6547 32.8453 21 34.5 21ZM36 22.5H33C32.175 22.5 31.4297 22.8328 30.8859 23.3719C32.775 24.4078 34.1156 26.2781 34.4062 28.5H37.5C38.3297 28.5 39 27.8297 39 27V25.5C39 23.8453 37.6547 22.5 36 22.5ZM24 22.5C26.9016 22.5 29.25 20.1516 29.25 17.25C29.25 14.3484 26.9016 12 24 12C21.0984 12 18.75 14.3484 18.75 17.25C18.75 20.1516 21.0984 22.5 24 22.5ZM27.6 24H27.2109C26.2359 24.4688 25.1531 24.75 24 24.75C22.8469 24.75 21.7688 24.4688 20.7891 24H20.4C17.4187 24 15 26.4188 15 29.4V30.75C15 31.9922 16.0078 33 17.25 33H30.75C31.9922 33 33 31.9922 33 30.75V29.4C33 26.4188 30.5812 24 27.6 24ZM17.1141 23.3719C16.5703 22.8328 15.825 22.5 15 22.5H12C10.3453 22.5 9 23.8453 9 25.5V27C9 27.8297 9.67031 28.5 10.5 28.5H13.5891C13.8844 26.2781 15.225 24.4078 17.1141 23.3719Z"
          fill="white"
        />
      </svg>
    );
  };
  
  export default FacebookGroupIcon;
  