const TumblrIcon = ({ className = '' }) => {
    return (
      <svg
        className={`h-6 w-6 ${className}`}
        width="39"
        height="39"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.8214 0H4.17857C1.87165 0 0 1.87165 0 4.17857V34.8214C0 37.1283 1.87165 39 4.17857 39H34.8214C37.1283 39 39 37.1283 39 34.8214V4.17857C39 1.87165 37.1283 0 34.8214 0ZM27.6569 31.7049C26.917 32.4971 24.9408 33.4286 22.3554 33.4286C15.7828 33.4286 14.3551 28.5971 14.3551 25.7766V17.9417H11.7696C11.4737 17.9417 11.2299 17.698 11.2299 17.402V13.7022C11.2299 13.3105 11.4737 12.9623 11.848 12.8317C15.2257 11.6391 16.279 8.69665 16.4357 6.45937C16.4792 5.85871 16.7926 5.57143 17.3063 5.57143H21.1627C21.4587 5.57143 21.7025 5.81518 21.7025 6.11116V12.379H26.2205C26.5165 12.379 26.7603 12.6228 26.7603 12.9187V17.3672C26.7603 17.6632 26.5165 17.9069 26.2205 17.9069H21.685V25.1585C21.685 27.0214 22.9734 28.0748 25.3848 27.1085C25.646 27.004 25.8723 26.9344 26.0813 26.9866C26.2728 27.0301 26.3946 27.1694 26.4817 27.4132L27.683 30.9127C27.7701 31.1913 27.8571 31.496 27.6569 31.7049Z"
          fill="#02343F"
        />
      </svg>
    );
  };
  
  export default TumblrIcon;
  