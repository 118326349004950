import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const ContentContainer = styled.div`
  ${tw`flex flex-col lg:flex-row`}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 16px 16px;

  @media (min-width: 1024px) {
    background-image: url("/whyus.jpg");
    min-height: 100vh;
  }

  @media (max-width: 1023px) {
    background-color: #df1c25;
    min-height: 400px;
  }
`;

const LeftColumn = styled.div`
  ${tw`text-white ml-8 xl:pl-0 lg:mt-40 mt-4  w-full md:w-3/4 xl:w-1/2`}
`;

const Content = tw.div`lg:mb-24 mt-6 flex flex-col sm:items-center lg:items-stretch xl:-ml-56`;

const Paragraph = styled.p`
  ${tw`max-w-[544px] md:mt-0 md:mb-4 md:text-4xl text-sm md:mt-6`}
  font-family: "Inter", sans-serif;

  font-weight: 700;

  text-align: left;
  color: #fff;
`;

const PointsContainer = styled.div`
  ${tw`flex flex-col mt-6 md:gap-[20px] gap-[12px]`}
  align-items: flex-start; /* Align points to the left */
`;

const Point = styled.div`
  ${tw`flex items-center gap-4`}
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #fff;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export default () => {
  return (
    <ContentContainer>
      <LeftColumn>
        <Content>
          <Paragraph>Why should you consider taking our course?</Paragraph>
          <PointsContainer>
            <Point>
              <Icon src="whyusicon.png" alt="Icon 1" />
              The course is designed to prepare you for real-world problem
              solving, crucial for hackathons and similar competitive events.
            </Point>
            <Point>
              <Icon src="whyusicon.png" alt="Icon 2" />
              It's a great way to stay updated with the latest trends in
              technology and programming.
            </Point>
            <Point>
              <Icon src="whyusicon.png" alt="Icon 3" />
              This course equips you with in-demand tech skills, making you
              stand out in competitions or job markets.
            </Point>
            <Point>
              <Icon src="whyusicon.png" alt="Icon 4" />
              The knowledge and experience gained from this course could be a
              stepping stone towards a successful career in tech.
            </Point>
          </PointsContainer>
        </Content>
      </LeftColumn>
    </ContentContainer>
  );
};
