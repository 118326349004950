import React from 'react';

const TwitterIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 62 62"
      fill="none"
      className={className}
    >
      <path
        d="M0 31C0 48.1208 13.8792 62 31 62C48.1208 62 62 48.1208 62 31C62 13.8792 48.1208 0 31 0C13.8792 0 0 13.8792 0 31Z"
        fill="#1DA1F2"
      />
      <path
        d="M46.5 21.3125C45.3375 21.8937 44.175 22.0875 42.8188 22.2812C44.175 21.5062 45.1437 20.3437 45.5312 18.7937C44.3687 19.5687 43.0125 19.9562 41.4625 20.3438C40.3 19.1812 38.5562 18.4062 36.8125 18.4062C32.7437 18.4062 29.6438 22.2812 30.6125 26.1562C25.3813 25.9625 20.7313 23.4438 17.4375 19.5688C15.6938 22.475 16.6625 26.1562 19.375 28.0938C18.4062 28.0938 17.4375 27.7063 16.4688 27.3188C16.4688 30.225 18.6 32.9375 21.5063 33.7125C20.5375 33.9062 19.5688 34.1 18.6 33.9062C19.375 36.425 21.7 38.3625 24.6062 38.3625C22.2812 40.1062 18.7938 41.075 15.5 40.6875C18.4062 42.4312 21.7 43.5938 25.1875 43.5938C37.0063 43.5938 43.5937 33.7125 43.2062 24.6062C44.5625 23.8312 45.725 22.6687 46.5 21.3125Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterIcon;
