const PintrestIcon = ({ className = '' }) => {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 60 60"
        fill="none"
      >
        <path
          d="M0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30Z"
          fill="#E60023"
        />
        <path
          d="M30 15C21.75 15 15 21.75 15 30C15 36.1875 18.75 41.4375 24 43.6875C24 42.5625 24 41.4375 24.1875 40.3125C24.5625 39 26.0625 32.0625 26.0625 32.0625C26.0625 32.0625 25.5 31.125 25.5 29.625C25.5 27.375 26.8125 25.6875 28.3125 25.6875C29.625 25.6875 30.375 26.625 30.375 27.9375C30.375 29.25 29.4375 31.3125 29.0625 33.1875C28.6875 34.6875 29.8125 36 31.5 36C34.3125 36 36.1875 32.4375 36.1875 27.9375C36.1875 24.5625 33.9375 22.125 30 22.125C25.5 22.125 22.6875 25.5 22.6875 29.25C22.6875 30.5625 23.0625 31.5 23.625 32.25C23.8125 32.625 24 32.625 23.8125 33C23.8125 33.1875 23.625 33.9375 23.4375 34.125C23.25 34.5 23.0625 34.6875 22.6875 34.5C20.625 33.5625 19.6875 31.3125 19.6875 28.6875C19.6875 24.375 23.25 19.3125 30.375 19.3125C36.1875 19.3125 39.9375 23.4375 39.9375 27.9375C39.9375 33.75 36.75 38.25 31.875 38.25C30.1875 38.25 28.6875 37.3125 28.125 36.375C28.125 36.375 27.1875 39.75 27 40.5C26.625 41.625 26.0625 42.75 25.5 43.6875C26.8125 44.0625 28.3125 44.25 29.8125 44.25C38.0625 44.25 44.8125 37.5 44.8125 29.25C45 21.75 38.25 15 30 15Z"
          fill="white"
        />
      </svg>
    );
  };
  
  export default PintrestIcon;
  