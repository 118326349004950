import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; // eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo1.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import Modal from "react-modal";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto mb-6
`;
const ContactUsForm = tw.form`flex items-center mt-4`;
const EmailInput = tw.input`w-40 h-10 text-gray-700 font-medium border rounded-l-md px-3 focus:outline-none`;
const ContactusButton = styled.a`
  ${tw`text-center inline-block cursor-pointer w-auto py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  background: #0B7077;

  height: 50px;
  padding: 20px 40px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center; /* Center the text horizontally */
  display: flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
  color: #ffffff;
`;

export const NavLinks = tw.div`inline-block mt-8 `;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent 
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! lg:ml-16 ml-6 `};

  img {
    ${tw`w-10 xl:-ml-20 lg:w-[326px] lg:h-[52px] w-[150px]`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex `,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};

const Navbar = ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const defaultLinks = [
    <ContactUsForm>
      <ContactusButton onClick={openModal}>CONTACT US</ContactusButton>
    </ContactUsForm>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/" className="cursor-pointer">
      <img
        src={window.location.pathname === "/" ? "/mainlogo.jpg" : logo}
        alt="logo"
        style={{
          marginTop: 18,
        }}
      />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6 mr-4" />
          ) : (
            <MenuIcon tw="w-6 h-6 mr-4" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Subscribe Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            overflow: "hidden",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "750px",
            height: "550px",
            border: "none",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            padding: "0",
            position: "relative",
            overflowY: "hidden",
          },
        }}
      >
        <button
          className="text-black px-2 py-2 rounded-full absolute top-2 right-2 cursor-pointer"
          onClick={closeModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-6 h-6"
            viewBox="0 0 24 24"
            style={{ position: "absolute", top: "2px", right: "2px" }}
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>

        <iframe
          src={`https://app.surveyed.live/?surveyCode=SUAIQTT4`}
          title="Subscribe iframe"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        ></iframe>
      </Modal>
    </Header>
  );
};

export default Navbar;
