const LinkedInBusinessIcon = ({ className = '' }) => {
    return (
      <svg
        className={`h-6 w-6 ${className}`}
        width="30"
        height="30"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="39" height="39" fill="#025A76" />
        <path
          d="M12.596 31.9994H7.41295V15.3086H12.596V31.9994ZM10.0017 13.0318C8.34431 13.0318 7 11.659 7 10.0017C7 9.20558 7.31625 8.44209 7.87917 7.87917C8.44209 7.31625 9.20558 7 10.0017 7C10.7978 7 11.5613 7.31625 12.1242 7.87917C12.6871 8.44209 13.0033 9.20558 13.0033 10.0017C13.0033 11.659 11.6585 13.0318 10.0017 13.0318ZM31.9944 31.9994H26.8225V23.8744C26.8225 21.9381 26.7835 19.4548 24.1278 19.4548C21.433 19.4548 21.0201 21.5586 21.0201 23.7349V31.9994H15.8426V15.3086H20.8136V17.5854H20.8862C21.5781 16.274 23.2684 14.8901 25.7902 14.8901C31.0357 14.8901 32 18.3443 32 22.8309V31.9994H31.9944Z"
          fill="white"
        />
      </svg>
    );
  };
  
  export default LinkedInBusinessIcon;
  