import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Modal from "react-modal";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo1.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter1.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as LocationIcon } from "../../images/location-icon.svg";
import { ReactComponent as PhoneIcon } from "../../images/phone-icon.svg";
import { ReactComponent as EmailIcon } from "../../images/email-icon.svg";
import { ReactComponent as ClockIcon } from "../../images/clock-icon.svg";

const Container = styled(ContainerBase)`
  background-image: url("footerbg.jpg");
  ${tw`text-gray-100 p-0 m-0`}
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: auto;
`;

const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 flex flex-wrap justify-between items-center`;

const LeftSection = tw.div`w-full lg:w-1/2 flex flex-col items-start`;
const RightSection = tw.div`w-full lg:w-1/2 flex flex-col items-start mt-10 lg:mt-0`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start `;
const LogoImg = tw.img`w-[326px] h-[52px] -ml-6`;

const InfoContainer = tw.div`mt-8 font-medium text-white`;
const ContactInfo = tw.p`text-lg font-medium mb-4`;
const AddressContainer = tw.div`flex items-start mb-6`;
const LocationText = tw.p`text-lg font-medium`;
const locationTextStyle = {
  fontFamily: "Poppins,sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "left",
};
const PhoneContainer = tw.div`flex items-start mb-4 `;
const PhoneText = tw.p`ml-2 text-lg font-medium`;
const EmailContainer = tw.div`flex items-start`;
const EmailText = tw.p`ml-2 text-lg font-medium`;
const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const ContactUsSection = tw.div`ml-auto flex flex-col items-center lg:items-start`;
const ContactUsLabel = tw.p`text-lg font-medium text-black`;
const ContactUsForm = tw.form`flex items-center mt-4`;
const EmailInput = tw.input`w-40 h-10 text-gray-700 font-medium border rounded-l-md px-3 focus:outline-none`;
const ContactusButton = styled.a`
  ${tw`text-center inline-block cursor-pointer w-auto py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  background: #0B7077;

  height: 50px;
  padding: 20px 40px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center; /* Center the text horizontally */
  display: flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
  color: #ffffff;
`;

const CopyrightText = styled.p`
  text-center mt-4 font-medium tracking-wide text-sm text-gray-600
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0;
  text-align: left;
  color: #718096;
  margin-top:40px;
`;

export default () => {
  const [isCourseModal, setIsCourseOpen] = useState(false);

  const openModal = () => {
    setIsCourseOpen(true);
  };

  const closeModal = () => {
    setIsCourseOpen(false);
  };
  return (
    <Container>
      <Content>
        <LeftSection>
          <LogoContainer>
            <LogoImg src={logo} />
          </LogoContainer>
          <InfoContainer>
            {/* <AddressContainer>
              <LocationIcon />
              <div
                style={{
                  marginLeft: 12,
                  fontSize: 14,
                  fontWeight: 700,
                  color: "#0B7077",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <p>Address:</p>
                <LocationText style={locationTextStyle}>
                  Mesa Verde Ave, Irving TX 75063
                </LocationText>
              </div>
            </AddressContainer> */}
            <AddressContainer>
              <PhoneIcon />
              <div
                style={{
                  marginLeft: 12,
                  fontSize: 14,
                  fontWeight: 700,
                  color: "#0B7077",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <p>Phone:</p>
                <PhoneText style={locationTextStyle}>
                  Tel: +1 972-372-4327
                </PhoneText>
              </div>
            </AddressContainer>
            <AddressContainer>
              <ClockIcon />
              <div
                style={{
                  marginLeft: 12,
                  fontSize: 14,
                  fontWeight: 700,
                  color: "#0B7077",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <p>Response hours:</p>
                <LocationText style={locationTextStyle}>8 to 20</LocationText>
              </div>
            </AddressContainer>
            {/* <AddressContainer>
              <EmailIcon />
              <div
                style={{
                  marginLeft: 12,
                  fontSize: 14,
                  fontWeight: 700,
                  color: "#0B7077",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <p>Email:</p>
                <LocationText style={locationTextStyle}>
                  Email: support@spicyfy.io
                </LocationText>
              </div>
            </AddressContainer> */}
          </InfoContainer>
        </LeftSection>
        <RightSection>
          <ContactUsSection>
            <ContactUsLabel>Contact Us</ContactUsLabel>
            <ContactUsForm>
              <ContactusButton onClick={openModal}>CONTACT US</ContactusButton>
            </ContactUsForm>
            <CopyrightText>
              2021-2023 &copy;Copyright Spicyfy Ventures LLC. All Rights
              Reserved
            </CopyrightText>
          </ContactUsSection>
        </RightSection>
      </Content>
      <Modal
        isOpen={isCourseModal}
        onRequestClose={closeModal}
        contentLabel="Subscribe Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            overflow: "hidden", // Hide the scrollbar on the overlay
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "750px",
            height: "550px", // Set the height to 550px
            border: "none",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            padding: "0",
            position: "relative", // Make the content position relative
            overflowY: "hidden", // Hide the vertical scrollbar
          },
        }}
      >
        <button
          className="text-black px-2 py-2 rounded-full absolute top-2 right-2 cursor-pointer"
          onClick={closeModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-6 h-6"
            viewBox="0 0 24 24"
            style={{ position: "absolute", top: "2px", right: "2px" }} // Adjust position
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>

        <iframe
          src={`https://app.surveyed.live/?surveyCode=SUAIQTT4`}
          title="Subscribe iframe"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        ></iframe>
      </Modal>
    </Container>
  );
};
