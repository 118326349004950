const PocketIcon = ({ className = '' }) => {
    return (
      <svg
        className={`h-6 w-6 ${className}`}
        width="35"
        height="32"
        viewBox="0 0 35 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.8438 0H3.17188C1.44531 0 0 1.44531 0 3.17188V13.7344C0 23.4766 7.78906 31.25 17.5156 31.25C27.2031 31.25 35 23.4766 35 13.7344V3.17188C35 1.42187 33.6172 0 31.8438 0ZM19.1875 20.9766C18.2188 21.8984 16.7344 21.8438 15.875 20.9766C6.99219 12.4688 6.89844 12.7656 6.89844 11.3516C6.89844 10.0312 7.97656 8.95312 9.29688 8.95312C10.625 8.95312 10.5547 9.25 17.5156 15.9297C24.5938 9.14062 24.4375 8.95312 25.7578 8.95312C27.0781 8.95312 28.1562 10.0312 28.1562 11.3516C28.1562 12.7422 27.9297 12.5781 19.1875 20.9766Z"
          fill="#F26E6E"
        />
      </svg>
    );
  };
  
  export default PocketIcon;
  