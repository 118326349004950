import tw from "twin.macro";
import React, { useState } from "react";
import styled from "styled-components";
import Header from "components/hero/Header";
import Footer from "components/hero/footer";
import { Helmet } from "react-helmet";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FiFacebook, FiTwitter } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-simple-toasts";

import Modal from "react-modal";
import FacebookIcon from "components/icons/FacebookIcon";
import WhatsAppIcon from "components/icons/WhatsAppIcon";
import LinkedinIcon from "components/icons/LinkedinIcon";
import TwitterIcon from "components/icons/TwitterIcon";
import Copytoclip from "components/icons/Copytoclip";
import FacebookGroupIcon from "components/icons/FacebookGroupIcon";
import EmailIcon from "components/icons/EmailIcon";
import LinkedInBusinessIcon from "../components/icons/LinkedInBusinessIcon";
import TelegramIcon from "components/icons/TelegramIcon";
import InstagramBusinessIcon from "components/icons/InstagramBusinessIcon";
import PintrestIcon from "components/icons/PintrestIcon";
import RedditIcon from "components/icons/RedditIcon";
import TumblrIcon from "components/icons/TumblrIcon";
import LiveJournalIcon from "components/icons/LiveJournalIcon";
import MailruIcon from "components/icons/MailruIcon";
import WorkplaceIcon from "components/icons/WorkplaceIcon";
import LineIcon from "components/icons/LineIcon";
import WeiboIcon from "components/icons/WeiboIcon";
import PocketIcon from "components/icons/PocketIcon";
import InstaPaperIcon from "components/icons/InstaPaperIcon";
import BHatenaIcon from "components/icons/BHatenaIcon";

const ContentContainer = tw.div`flex flex-col xl:flex-row sm:pt-12 pt-4 px-4 mb-24`;

const LeftColumn = styled.div`
  ${tw`bg-contain bg-center w-full md:ml-12`}/* Adjust padding as needed */
`;

const CourseTitle = styled.h1`
  ${tw`text-4xl font-semibold text-black mb-4`}
`;

const nameArray = [
  "Facebook Page",
  "Whatsapp",
  "LinkedIn Profile",
  "Twitter",
  "Copy to Clipboard",
  // 'Share Link to Watch',
  "Facebook Group",
  "Mail",
  "LinkedIn Page",
  // 'Instagram Business',
  // 'Present',
  // 'Messenger',
  "Telegram",
  "Pinterest",
  "Reddit",
  "Tumblr",
  "LiveJournal",
  "mail.ru",
  "Workplace",
  "Line",
  "Pocket",
  "Insta Paper",
  "B Hatena",
];

const LoopingButtonFunction = (props) => {
  if (props.id === "Copy to Clipboard")
    return (
      <CopyToClipboard
        text="https://kidsaiclub.com/nasaspaceapps"
        onCopy={() => toast("Text has been copied!")}
      >
        {props.children}
      </CopyToClipboard>
    );

  if (props.id === "Instagram Business") return <div>{props.children}</div>;

  return <div className="cursor-pointer">{props.children}</div>;
};
const Loopingfunction = (data) => {
  if (data == "Facebook Page")
    return <FacebookIcon style={{ width: 6, height: 6 }} />;
  // Other conditions for different icons

  if (data == "Whatsapp") return <WhatsAppIcon className="h-6 w-6" />;
  if (data == "LinkedIn Profile") return <LinkedinIcon className="h-6 w-6" />;
  if (data == "Twitter") return <TwitterIcon className="h-6 w-6" />;

  if (data == "Copy to Clipboard") return <Copytoclip className="h-6 w-6" />;

  if (data == "Facebook Group")
    return <FacebookGroupIcon className="h-6 w-6" />;
  if (data == "Mail") return <EmailIcon className="h-6 w-6" />;
  if (data == "LinkedIn Page")
    return <LinkedInBusinessIcon className="h-6 w-6" />;

  // if (data == 'Present') return <PresentIcon className="h-6 w-6" />;
  // if (data == 'Messenger') return <MessangerIcon className="h-6 w-6" />;
  if (data == "Telegram") return <TelegramIcon className="h-6 w-6" />;
  if (data == "Pinterest") return <PintrestIcon className="h-6 w-6" />;

  if (data == "Reddit") return <RedditIcon className="h-6 w-6" />;
  if (data == "Tumblr") return <TumblrIcon className="h-6 w-6" />;

  if (data == "LiveJournal") return <LiveJournalIcon className="h-6 w-6" />;
  if (data == "mail.ru") return <MailruIcon className="h-6 w-6" />;
  if (data == "Workplace") return <WorkplaceIcon className="h-6 w-6" />;
  if (data == "Line") return <LineIcon className="h-6 w-6" />;

  if (data == "Weibo") return <WeiboIcon className="h-6 w-6" />;
  if (data == "Pocket") return <PocketIcon className="h-6 w-6" />;
  if (data == "Insta Paper") return <InstaPaperIcon className="h-6 w-6" />;
  if (data == "B Hatena") return <BHatenaIcon className="h-6 w-6" />;
};

const CourseDescription = styled.p`
  ${tw`text-lg font-medium text-black mb-6 w-full`}
`;

const CoursePoints = styled.ul`
  ${tw`text-lg font-medium text-black`}
`;

const CoursePoint = styled.li`
  color: #4e5566; /* Default color for the entire CoursePoint text */
`;

const PointIcon = styled.img`
  ${tw`w-6 h-6 mr-3`}
`;

const LeftColumnContent = styled.div`
  ${tw`flex flex-col items-start w-full`}
`;

const RightColumn = styled.div`
  ${tw`xl:w-[45%] mr-6 xl:h-[770px]`}
`;

const Content = tw.div`lg:mb-24`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0 flex justify-between items-center`}
`;

const GetStartedText = styled.p`
  ${tw`text-lg font-semibold text-black`}
`;

const ActionsContainer = styled.div`
  ${tw`flex items-center`}
`;

const StartButton = styled.a`
  ${tw`text-center py-2 px-6 font-semibold tracking-wide rounded bg-indigo-600 text-white hocus:outline-none focus:shadow-outline transition duration-300`}
`;

const WhiteButton = styled.a`
  ${tw`text-center inline-block w-auto py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  background: #FFEEE8;
  padding: 12px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0b7077;
`;

const Heading = styled.h1`
  font-family: "Raleway", sans-serif;
  font-size: 48px;
  font-weight: 700;
  text-align: left;
  color: #fd661f;
  margin-top: 16px;
`;

const Description = styled.p`
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
`;

const PriceContainer = styled.div`
  ${tw`mt-4`}
`;

const PriceLabel = styled.span`
  ${tw`block mb-2 text-lg font-semibold text-gray-700`}
`;

const Price = styled.span`
  ${tw`text-4xl font-bold text-indigo-600`}
`;

const RegisterButtons = styled.div`
  ${tw`flex flex-col space-y-4  p-4`}
`;

const ShareButton = styled.a`
  ${tw`text-center w-full py-4 font-semibold tracking-wide rounded bg-[#FF6636] font-semibold text-white focus:outline-none focus:shadow-outline transition duration-300`}
`;

const RegisterButton = styled.a`
  ${tw`text-center w-full py-4 font-semibold tracking-wide rounded bg-[#FFEEE8] font-semibold text-white focus:outline-none focus:shadow-outline transition duration-300`}
`;

const CourseIncludes = styled.div`
  ${tw`mt-8`}
`;

const IncludeItem = styled.div`
  ${tw`flex items-center mb-2`}
`;

const IncludeIcon = styled.img`
  ${tw`w-6 h-6 mr-3`}
`;

const IncludeText = styled.p`
  ${tw`text-lg font-medium text-gray-700`}
`;

const OverviewSection = styled.div`
  ${tw`mt-8 border-t border-gray-300 pt-8`}
`;

export default () => {
  const [isCourseModal, setIsCourseOpen] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const openShareModal = () => {
    setIsShare(true);
  };

  const closeShareModal = () => {
    setIsShare(false);
  };

  const openModal = () => {
    setIsCourseOpen(true);
  };

  const closeModal = () => {
    setIsCourseOpen(false);
  };

  const [searchState, setSearchState] = useState("");

  const [shareLink, setShareLink] = useState(
    "https://kidsaiclub.com/nasaspaceapps"
  );

  const handleShare = (platform) => {
    const shareUrl = "https://kidsaiclub.com/nasaspaceapps"; // Replace this with your actual share URL
    let shareLink = "";

    switch (platform) {
      case "Facebook Page":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;

      case "Facebook Group":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;

      case "Mail":
        const emailSubject = "Check out this link!";
        const emailBody = `I thought you might be interested in this link: ${shareUrl}`;
        shareLink = `mailto:?subject=${encodeURIComponent(
          emailSubject
        )}&body=${encodeURIComponent(emailBody)}`;
        break;

      case "Twitter":
        shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}`;

        break;
      case "Tumblr":
        shareLink = `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "LiveJournal":
        shareLink = `https://www.livejournal.com/update.bml?event=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "Mail.ru":
        shareLink = `https://connect.mail.ru/share?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "Workplace":
        shareLink = `https://work.facebook.com/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "Line":
        shareLink = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "Pocket":
        shareLink = `https://getpocket.com/save?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "B Hatena":
        shareLink = `http://b.hatena.ne.jp/add?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "Insta Paper":
        shareLink = `https://www.instapaper.com/save?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;

      case "Telegram":
        shareLink = `https://t.me/share/url?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "Reddit":
        shareLink = `https://www.reddit.com/submit?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "Pinterest":
        shareLink = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
          shareUrl
        )}`;
        break;

      case "Whatsapp":
        shareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "LinkedIn Profile":
        shareLink = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
          shareUrl
        )}`;
      case "LinkedIn Page":
        shareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          shareUrl
        )}`;

        break;
      default:
        break;
    }

    if (shareLink) {
      window.open(shareLink, "_blank");
    }
  };

  const handleFacebookShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareLink
      )}`,
      "_blank"
    );
  };

  const handleTwitterShare = () => {
    const text = "Check out this amazing course!";
    const twitterHandle = "YourTwitterHandle"; // Replace with your Twitter handle
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        shareLink
      )}&text=${encodeURIComponent(text)}&via=${twitterHandle}`,
      "_blank"
    );
  };

  const handleWhatsAppShare = () => {
    const text = "Check out this amazing course!";
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        text + " " + shareLink
      )}`,
      "_blank"
    );
  };
  return (
    <div>
      <Helmet>
        <title>Young Stars: Kids' Training for NASA Space Apps Challenge</title>
        <meta
          name="description"
          content="Young Stars: Kids' Training for NASA Space Apps Challenge is a course designed to equip children with skills to compete in NASA's global hackathon."
        />
        <meta
          property="og:title"
          content="Young Stars: Kids' Training for NASA Space Apps Challenge"
        />
        <meta
          property="og:description"
          content="Young Stars: Kids' Training for NASA Space Apps Challenge is a course designed to equip children with skills to compete in NASA's global hackathon."
        />
        <meta property="og:image" content="/spaceappsog.jpg" />
        {/* Add more Open Graph tags as needed */}
      </Helmet>
      <Header />
      <ContentContainer id="aboutus">
        <LeftColumn>
          <LeftColumnContent>
            <div
              style={{
                fontWeight: "400",
                color: "#6E7485",
                fontFamily: "Inter, sans-serif",
                fontSize: "12px",
              }}
            >
              <a href="#" tw="text-gray-500 text-[40px] mb-2">
                <a
                  href="/"
                  style={{ color: "#6E7485", textDecoration: "none" }}
                >
                  Home
                </a>{" "}
                <span>&gt;</span>{" "}
                <a href="/nasaspaceapps" tw="text-gray-500 text-sm mb-2">
                  Young Stars: Kids' Training for NASA Space Apps Challenge
                </a>
              </a>
            </div>
            <CourseTitle
              style={{
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                fontSize: "32px",
              }}
            >
              Young Stars: Kids' Training for NASA Space Apps Challenge
            </CourseTitle>
            <CourseDescription
              style={{ color: "#4E5566", fontFamily: "Inter, sans-serif" }}
            >
              Young Stars: Kids' Training for NASA Space Apps Challenge" is a
              course designed to equip children with skills to compete in NASA's
              global hackathon.
            </CourseDescription>

            <img src="/spaceappsog.jpg" alt="Course Image" className="w-full" />
            <div
              style={{
                color: "#1D2026",
                fontFamily: "Inter, sans-serif",
                fontWeight: 500,
                marginTop: 12,
                borderBottomWidth: 2,
                borderBottomColor: "#FF6636",
              }}
            >
              Overview
            </div>
            <OverviewSection>
              <Description
                style={{
                  color: "#1D2026",
                  fontWeight: "600",
                  marginBottom: 8,
                  fontFamily: "Inter, sans-serif",
                }}
              >
                Description
              </Description>
              <CoursePoints>
                <CoursePoint
                  style={{
                    fontWeight: "400",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "18px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                      fontFamily: "Inter, sans-serif",
                      color: "#000000",
                    }}
                  >
                    1. Introduction to Space Science:
                  </span>{" "}
                  The course covers basic knowledge about space and NASA
                  missions, essential for the NASA Space Apps Challenge.
                </CoursePoint>
                <CoursePoint
                  style={{
                    fontWeight: "400",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "18px",
                    marginTop: 8,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                      fontFamily: "Inter, sans-serif",
                      color: "#000000",
                    }}
                  >
                    2. Coding Skills:
                  </span>{" "}
                  It teaches children coding skills required to create solutions
                  for the challenges posed in the hackathon.
                </CoursePoint>
                <CoursePoint
                  style={{
                    fontWeight: "400",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "18px",
                    marginTop: 8,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                      fontFamily: "Inter, sans-serif",
                      color: "#000000",
                    }}
                  >
                    3. Teamwork and Collaboration:
                  </span>{" "}
                  The course encourages children to work in teams, fostering
                  problem-solving, communication, and collaboration skills.
                </CoursePoint>
                <CoursePoint
                  style={{
                    fontWeight: "400",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "18px",
                    marginTop: 8,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                      fontFamily: "Inter, sans-serif",
                      color: "#000000",
                    }}
                  >
                    4. Understanding of NASA's Data:
                  </span>{" "}
                  It helps children understand how to use NASA's open-source
                  data to solve real-world problems.
                </CoursePoint>
                <CoursePoint
                  style={{
                    fontWeight: "400",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "18px",
                    marginTop: 8,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                      fontFamily: "Inter, sans-serif",
                      color: "#000000",
                    }}
                  >
                    5. Competition Preparation:
                  </span>{" "}
                  The course prepares kids for the NASA Space Apps Challenge,
                  including developing ideas, implementing solutions, and
                  presentation skills.
                </CoursePoint>
              </CoursePoints>
            </OverviewSection>
          </LeftColumnContent>
        </LeftColumn>
        <RightColumn style={{ borderWidth: 2, borderColor: "#E9EAF0" }}>
          <Content>
            <Actions style={{ marginBottom: 3, padding: 12 }}>
              <GetStartedText
                style={{
                  color: "#1D2026",
                  fontFamily: "Inter, sans-serif",
                  fontSize: 22,
                  fontWeight: 400,
                }}
              >
                $100.00/Month
              </GetStartedText>
              <ActionsContainer>
                <WhiteButton
                  style={{
                    color: "#FF6636",
                    fontWeight: 500,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  SUMMER SPECIAL
                </WhiteButton>
              </ActionsContainer>
            </Actions>
            <div style={{ borderWidth: 1, borderColor: "#E9EAF0" }} />
            <Actions style={{ padding: 6 }}>
              <IncludeItem>
                <IncludeIcon src="/clockicon.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#1D2026",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  Course Duration
                </IncludeText>
              </IncludeItem>

              <IncludeText
                style={{
                  color: "#6E7485",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 400,
                  fontSize: 16,
                }}
              >
                7 Month
              </IncludeText>
            </Actions>
            <Actions style={{ padding: 6 }}>
              <IncludeItem>
                <IncludeIcon src="/graphicon.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#1D2026",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  Course Level
                </IncludeText>
              </IncludeItem>

              <IncludeText
                style={{
                  color: "#6E7485",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 400,
                  fontSize: 16,
                }}
              >
                Beginner and Intermediate
              </IncludeText>
            </Actions>
            <Actions style={{ padding: 6 }}>
              <IncludeItem>
                <IncludeIcon src="/languageicon.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#1D2026",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  Language
                </IncludeText>
              </IncludeItem>

              <IncludeText
                style={{
                  color: "#6E7485",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 400,
                  fontSize: 16,
                }}
              >
                English
              </IncludeText>
            </Actions>
            <div
              style={{
                borderWidth: 1,
                borderColor: "#E9EAF0",
              }}
            />
            <RegisterButtons style={{ cursor: "pointer" }}>
              <ShareButton
                onClick={openShareModal}
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 600,
                  fontSize: 18,
                  color: "white",
                }}
              >
                Share
              </ShareButton>
              <RegisterButton
                onClick={openModal}
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#FF6636",
                }}
              >
                Register
              </RegisterButton>
            </RegisterButtons>
            <span
              style={{
                marginRight: "5px",
                fontFamily: "Inter, sans-serif",
                marginLeft: 8,
                color: "#6E7485",
                fontWeight: 400,
              }}
            >
              Note:
            </span>{" "}
            <span
              style={{
                color: "#8C94A3",
                fontFamily: "Inter, sans-serif",
                fontWeight: 400,
              }}
            >
              {" "}
              all course have 30-days money-back guarantee
            </span>
            <div
              style={{ borderWidth: 1, borderColor: "#E9EAF0", marginTop: 12 }}
            />
            <CourseIncludes>
              <div
                style={{
                  fontWeight: "600",
                  color: "#1D2026",
                  fontFamily: "Inter, sans-serif",
                  padding: 4,
                }}
              >
                This course includes:
              </div>
              <IncludeItem style={{ paddingLeft: 6 }}>
                <IncludeIcon src="/colorclock.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#4E5566",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  Lifetime access to all Training videos
                </IncludeText>
              </IncludeItem>
              <IncludeItem style={{ paddingLeft: 6 }}>
                <IncludeIcon src="/accessicon.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#4E5566",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  30-days money-back guarantee
                </IncludeText>
              </IncludeItem>
              <IncludeItem style={{ paddingLeft: 6 }}>
                <IncludeIcon src="/guarantee.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#4E5566",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  Online coding practice exercises
                </IncludeText>
              </IncludeItem>
              <IncludeItem style={{ paddingLeft: 6 }}>
                <IncludeIcon src="/coding.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#4E5566",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  Shareable certificate of completion
                </IncludeText>
              </IncludeItem>
              <IncludeItem style={{ paddingLeft: 6 }}>
                <IncludeIcon src="/certificate.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#4E5566",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  Access on mobile , tablet and TV
                </IncludeText>
              </IncludeItem>
              <IncludeItem style={{ paddingLeft: 6 }}>
                <IncludeIcon src="/mobile.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#4E5566",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  English subtitles
                </IncludeText>
              </IncludeItem>
              <IncludeItem style={{ paddingLeft: 6 }}>
                <IncludeIcon src="/subtitles.jpg" alt="Icon" />
                <IncludeText
                  style={{
                    color: "#4E5566",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  Online Course Conducted by a Live Instructor
                </IncludeText>
              </IncludeItem>
            </CourseIncludes>
          </Content>
        </RightColumn>
      </ContentContainer>
      <Modal
        isOpen={isShare}
        onRequestClose={closeShareModal}
        contentLabel="Share Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            overflow: "hidden",
          },
          content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%", // Adjusted for responsiveness
            maxWidth: "750px", // Maximum width for larger screens
            maxHeight: "94%", // Adjusted for responsiveness
            border: "none",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            padding: "12px",
            position: "relative",
            overflowY: "auto",
          },
        }}
      >
        <button
          className="text-black px-2 py-2 rounded-full absolute top-2 right-2 cursor-pointer"
          onClick={closeShareModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-6 h-6"
            viewBox="0 0 24 24"
            style={{
              position: "absolute",
              top: "2px",
              right: "2px",
              marginRight: 12,
              marginTop: 4,
            }} // Adjust position
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <div
          className="modal-content"
          style={{
            maxHeight: "calc(100% - 80px)", // Adjust height as needed
            overflowY: "auto",
          }}
        >
          <div className="my-2 ">
            <p
              className="text-lg "
              style={{
                fontFamily: "'Poppins', Arial, sans-serif",
                fontWeight: 600,
                fontStyle: "normal",
              }}
            >
              Recommended
            </p>
            <div
              style={{
                display: "flex",
                gap: 40,
                marginTop: 2,
                paddingLeft: 40,
              }}
            >
              {nameArray.slice(0, 4).map((name, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LoopingButtonFunction id={name}>
                        <div
                          style={{
                            width: 60, // Increased width for the rounded square
                            height: 60, // Increased height for the rounded square
                            borderRadius: 8, // Border radius for the rounded square
                            backgroundColor: "white", // Red background color
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "2px solid #F5F5F5",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                          onClick={() => handleShare(name)}
                        >
                          <div style={{ width: 28, height: 28 }}>
                            {Loopingfunction(name)}
                          </div>
                        </div>
                      </LoopingButtonFunction>
                      <p
                        className="text-xs"
                        style={{
                          fontFamily: "'Poppins', Arial, sans-serif",
                          fontWeight: 500,
                        }}
                      >
                        {name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr
              style={{ marginTop: 2 }}
              className="h-0.5 px-0.5 w-full bg-gray-shadeeight opacity-50"
            />
          </div>
          <div style={{ marginTop: 2 }}>
            <p
              className="text-lg"
              style={{
                fontFamily: "'Poppins', Arial, sans-serif",
                fontWeight: 600,
                fontStyle: "normal",
              }}
            >
              Share
            </p>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 12,
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {nameArray
                .filter((each) =>
                  each.toLowerCase().includes(searchState.toLowerCase())
                )
                .map((name, index) => {
                  return (
                    <div
                      key={index}
                      className="h-[100px] w-[70px] mx-2 lg:mx-4 my-2 text-center font-MetroRegular flex flex-col justify-start items-center"
                      style={{ flex: "0 0 calc(16.666% - 24px)" }} // Adjust width to fit 6 items in a row
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LoopingButtonFunction id={name}>
                          <div
                            style={{
                              width: 60, // Increased width for the rounded square
                              height: 60, // Increased height for the rounded square
                              borderRadius: 8, // Border radius for the rounded square
                              backgroundColor: "white", // Red background color
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              border: "2px solid #F5F5F5",
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                            onClick={() => handleShare(name)}
                          >
                            <div style={{ width: 28, height: 28 }}>
                              {Loopingfunction(name)}
                            </div>
                          </div>
                        </LoopingButtonFunction>
                        <p
                          className="text-xs "
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            fontFamily: "'Poppins', Arial, sans-serif",
                            fontWeight: 500,
                          }}
                        >
                          {name}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* Share content */}
      </Modal>

      <Modal
        isOpen={isCourseModal}
        onRequestClose={closeModal}
        contentLabel="Subscribe Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            overflow: "hidden", // Hide the scrollbar on the overlay
          },
          content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%", // Adjusted width for responsiveness
            maxWidth: "750px", // Maximum width to maintain readability
            height: "90%", // Adjusted height for responsiveness
            maxHeight: "550px", // Maximum height to maintain readability
            border: "none",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            padding: "0",
            position: "relative",
            overflowY: "hidden", // Enable vertical scrolling if content exceeds height
          },
        }}
      >
        <button
          className="text-black px-2 py-2 rounded-full absolute top-2 right-2 cursor-pointer"
          onClick={closeModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-6 h-6"
            viewBox="0 0 24 24"
            style={{
              position: "absolute",
              top: "2px",
              right: "2px",
              marginRight: 12,
              marginTop: 4,
            }} // Adjust position
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>

        <div
          style={{
            marginTop: 16,
            height: "100%",
          }}
        >
          {" "}
          <iframe
            src={`https://app.surveyed.live/?surveyCode=FOQWKPQC`}
            title="Subscribe iframe"
            width="100%"
            height="100%"
            style={{ border: "none" }}
          ></iframe>
        </div>
      </Modal>
      <Footer />
    </div>
  );
};
