import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "./light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0 `}
  }
`;

const NavLink = styled(NavLinkBase)`
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #252641;
  marginbottom: 24;
`;

const ContactusButton = styled.a`
  ${tw`text-center inline-block w-auto py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  background: #0B7077;
  height: 50px;
  padding: 20px 40px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;

export default () => {
  return (
    <StyledHeader collapseBreakpointClass="sm">
      <NavLinks>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 36,
          }}
        >
          <NavLink href="#about">About Us</NavLink>
          <ContactusButton>CONTACT US</ContactusButton>
        </div>
      </NavLinks>
    </StyledHeader>
  );
};
