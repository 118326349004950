import React from "react";
import { useParams } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import HotelTravelLandingPage from "demos/index.js";

import HotelTravelLandingPageImageSrc from "images/demo/HotelTravelLandingPage.jpeg";

import FullWidthWithImageHero from "components/hero/HeroSection.js";

import ThreeColSimpleFeatures from "components/hero/PopularCourses.js";

import ThreeColSimpleWithImageBlog from "components/blogs/ThreeColSimpleWithImage.js";

// import PopularAndRecentPostsBlog from "components/blogs/PopularAndRecentBlogPosts.js";

import TwoColumnWithImageAndProfilePictureReviewTestimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";

import SimpleWithSideImageFAQS from "components/hero/Faqs.js";

// import SimpleSubscribeNewsletterForm from "components/forms/SimpleSubscribeNewsletter.js";

import MiniCenteredFooter from "components/hero/footer.js";

export const components = {
  landingPages: {
    HotelTravelLandingPage: {
      component: HotelTravelLandingPage,
      imageSrc: HotelTravelLandingPageImageSrc,
      url: "/components/landingPages/HotelTravelLandingPage",
    },
  },

  innerPages: {},

  blocks: {
    Hero: {
      type: "Hero Section",
      elements: {
        FullWidthWithImage: {
          name: "Full Width With Image",
          component: FullWidthWithImageHero,
          url: "/components/blocks/Hero/FullWidthWithImage",
        },
      },
    },
    Pricing: {
      type: "Pricing Section",
      elements: {},
    },
    Features: {
      type: "Features Section",
      elements: {
        ThreeColSimple: {
          name: "Three Column Simple",
          component: ThreeColSimpleFeatures,
          url: "/components/blocks/Features/ThreeColSimple",
        },
      },
    },

    Cards: {
      type: "Cards",
      elements: {},
    },

    Blog: {
      type: "Blog Section",
      elements: {
        // PopularAndRecentPosts: {
        //   name: "Popular And Recent Posts",
        //   component: PopularAndRecentPostsBlog,
        //   url: "/components/blocks/Blog/PopularAndRecentPosts",
        // },
        ThreeColSimpleWithImage: {
          name: "Simple Three Column With Image",
          component: ThreeColSimpleWithImageBlog,
          url: "/components/blocks/Blog/ThreeColSimpleWithImage",
        },
      },
    },

    Testimonial: {
      type: "Testimonial Section",
      elements: {
        TwoColumnWithImageAndProfilePictureReview: {
          name: "Two Column With Image And Profile Picture Review",
          component: TwoColumnWithImageAndProfilePictureReviewTestimonial,
          url: "/components/blocks/Testimonial/TwoColumnWithImageAndProfilePictureReview",
        },
      },
    },

    FAQS: {
      type: "FAQs Section",
      elements: {
        SimpleWithSideImage: {
          name: "Simple With Side Image",
          component: SimpleWithSideImageFAQS,
          url: "/components/blocks/FAQS/SimpleWithSideImage",
        },
      },
    },

    Form: {
      type: "Forms Section",
      elements: {
        // SimpleSubscribeNewsletter: {
        //   name: "Simple Subscribe newsletter",
        //   component: SimpleSubscribeNewsletterForm,
        //   url: "/components/blocks/Form/SimpleSubscribeNewsletter",
        // },
      },
    },

    CTA: {
      type: "CTA Section",
      elements: {},
    },

    Footer: {
      type: "Footers Section",
      elements: {
        MiniCentered: {
          name: "Mini Centered Dark",
          component: MiniCenteredFooter,
          url: "/components/blocks/Footer/MiniCentered",
        },
      },
    },
  },
};

export default () => {
  const { type, subtype, name } = useParams();

  try {
    let Component = null;
    if (type === "blocks" && subtype) {
      Component = components[type][subtype]["elements"][name].component;
      return (
        <AnimationRevealPage disabled>
          <Component />
        </AnimationRevealPage>
      );
    } else Component = components[type][name].component;

    if (Component) return <Component />;

    throw new Error("Component Not Found");
  } catch (e) {
    console.log(e);
    return <div>Error: Component Not Found</div>;
  }
};
